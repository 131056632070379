import Tooltip from "tether-tooltip";
import { get_attr } from "../util/dom";

export default (el) => {
    const tooltip = get_attr(el, "data-content", false);
    return new Tooltip({
        target: el,
        content: tooltip,
        position: "bottom left",
        offset: "10"
    });
};

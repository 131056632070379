import closest from "closest";

function to_array(iterable) {
    return [].slice.call(iterable);
}

function $(selector, el = document) {
    return el.querySelector(selector);
}

function $$(selector, el = document) {
    return to_array(el.querySelectorAll(selector));
}

function each(els, action, ...args) {
    return els.map((el) => {
        return action.apply(null, [].concat(el, args));
    });
}

function index(el) {
    let index = 0;

    while(el = el.previousElementSibling) {
        index++;
    }

    return index;
}

function add_class(el, clazz) {
    el.classList.add(clazz);
}

function toggle_class(el, clazz, force = false) {
    el.classList.toggle(clazz, force);
}

function remove_class(el, clazz) {
    el.classList.remove(clazz);
}

function get_attr(el, name, _default = void 0) {
    return el && el.hasAttribute(name) ? el.getAttribute(name) : _default;
}

function set_attr(el, name, value, append = false) {
    const attr_value = append ? get_attr(el, name, "") + " " + value : value;
    el.setAttribute(name, attr_value);
    return el;
}

function has_attr(el, name) {
    return el && el.hasAttribute(name);
}

function value(el) {
    if(el.tagName === "TEXTAREA" || el.tagName === "BUTTON") {
        return el.value;
    } else if(el.tagName === "SELECT") {
        // todo multiple?
        return el.options[el.selectedIndex].value;
    } else if(el.tagName === "INPUT") {
        const type = el.getAttribute("type").toLowerCase();

        if(type === "checkbox" || type === "radio") {
            return el.checked;
        } else {
            return el.value;
        }
    } else {
        throw new Error("unknown tag type");
    }
}

function component(name, init) {
    function parse_conf(el) {
        const default_conf = {};

        try {
            const conf = JSON.parse(el.getAttribute("data-component-conf"));
            return conf || default_conf;
        } catch(e) {
            return default_conf;
        }
    }

    return $$(`[data-component~="${name}"]`)
        .map((el, index) => {
            const def = el.getAttribute("data-component").split(" ");

            const options = {
                index,
                conf: parse_conf(el),
                is_type(type) {
                    return def.indexOf(`${name}-${type}`) !== -1;
                }
            };

            return init(el, options);
        });
}


export {
    $, $$, component, each,
    index, closest, value,
    add_class, remove_class, toggle_class,
    get_attr, has_attr, set_attr
}


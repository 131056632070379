import delegate from "delegate";
import { $, $$, each, get_attr, index } from "../util/dom";

import Photoswipe from "photoswipe";
import theme from "photoswipe/dist/photoswipe-ui-default";

function calculate_bounding(images, index) {
    const image = images[index];
    const pageYScroll = window.pageYOffset || document.documentElement.scrollTop;
    const rect = image.getBoundingClientRect();

    return {
        x: rect.left,
        y: rect.top + pageYScroll,
        w: rect.width
    };
}

function create_lightbox(gallery) {
    const gallery_id = get_attr(gallery, "data-gallery-id");
    const lightbox_el = $(`[data-gallery-lightbox="${gallery_id}"]`);
    const image_selector = "[href]";
    const image_els = $$(image_selector, gallery);
    const images = each(image_els, (el) => {
        const [ w, h ]= each(get_attr(el, "data-size").split("x"), parseInt);
        return {
            msrc: get_attr($("img", el), "src"),
            src: get_attr(el, "href"),
            w, h
        }
    });

    function show(start_with_index = 0) {
        const lightbox = new Photoswipe(lightbox_el, theme, images, {
            index: start_with_index,
            getThumbBoundsFn: calculate_bounding.bind(null, image_els),
            zoomEl: false,
            shareEl: false
        });
        lightbox.init();
    }

    const cleanup_click_listener = delegate(gallery, image_selector, "click", function(e) {
        e.preventDefault();
        const target_index = index(e.delegateTarget.parentNode);
        show(target_index)
    });

    return {
        show: show,
        remove: () => {
            cleanup_click_listener();
        }
    }
}

export default (el) => {
    return create_lightbox(el);
}

import Gator from "gator";
import { $$, $, value } from "../util/dom";

function int(val) {
    return parseInt(val, 10);
}

function partial_right(func, right_arg) {
    return function() {
        const args = [].slice.call(arguments);
        args.push(right_arg);
        return func.apply(this, args);
    }
}

function search(attr) {
    return (el, data = "") => {
        return el.getAttribute(attr).indexOf(data.toLowerCase()) !== -1;
    }
}

function matches_single(attr) {
    return (el, data = null) => {
        return isNaN(data) || !data || int(el.getAttribute(attr)) === data;
    }
}

function matches_all(attr) {
    return (el, data = []) => {
        const vals = el.getAttribute(attr).split(" ").map(int);
        return data.reduce((valid, value) => valid && vals.indexOf(value) !== -1, true);
    }
}

function match_chain(el, matchers) {
    return matchers.reduce((valid, matcher) => valid && matcher(el), true);
}

function CollectionTest(items, callback, collection_callback) {
    const test_matcher = {};
    const test_data = {};
    const iface = {};

    function test() {
        const tests = Object.keys(test_matcher).map((name) => partial_right(test_matcher[name], test_data[name]));

        items
            .map((el) => ({ el: el, passes: match_chain(el, tests) }))
            .forEach((data) => callback(data.el, data.passes));

        collection_callback(items);
    }

    iface.add_test = (name, matcher) => {
        test_matcher[name] = matcher;
        return iface;
    };

    iface.update_data = (name, data) => {
        test_data[name] = data;
        test();
        return iface;
    };

    return iface;
}

export default (el) => {
    const filter_props = [];

    const items = new CollectionTest($$(".food"), (el, passes) => {
        el.parentNode.classList.toggle("filter-hide", !passes);
    }, () => {
        $$(".food-selection").forEach((selection) => {
            const hide = $$(".foods > li", selection).length === $$(".foods > li.filter-hide", selection).length;
            const header = $(".food-type", selection);

            selection.classList.toggle("filter-hide", hide);
            header.classList.toggle("filter-hide", hide);
        })
    });

    items.add_test("type", matches_single("data-food-type"));
    items.add_test("props", matches_all("data-food-props"));
    items.add_test("search", search("data-food-search"));

    Gator(el).on("change", "select", function() {
        const val = value(this);
        items.update_data("type", val ? int(val) : null);
    });

    Gator(el).on("click", "button", function() {
        const val = int(value(this));
        const index = filter_props.indexOf(val);

        if(index === -1) {
            filter_props.push(val);
        } else {
            filter_props.splice(index, 1);
        }

        this.classList.toggle("pressed", index === -1);
        items.update_data("props", filter_props);
    });

    Gator(el).on("input", ".filter-search", function() {
        const val = value(this);
        items.update_data("search", val);
    });
}
